import React from "react";

export const Blog = () => {
  return (
    <div>
      <h1>Blog</h1>

     

    </div>
  );
};
