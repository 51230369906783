import React, { Fragment } from 'react'

const Productswitches = () => {
  return (
    <Fragment>

    </Fragment>
  )
}

export default Productswitches